import React, { useEffect, useState } from "react";

// packages
import { useMsal } from "@azure/msal-react";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../redux/reducers/user-reducer";

//api
import { productToCart } from "../../../api";
import DownloadModal from "../../Modal/Download";
import { useNavigate } from "react-router-dom";

export default function ActionButtons({ product }) {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [pluginMode, setPluginMode] = useState(false);

  const user = useSelector((store) => store.user.user);

  const handleCart = (id) => {
    if (accounts[0]) {
      let payload = {
        tenantId: accounts[0]?.localAccountId,
        name: accounts[0]?.name,
        email: accounts[0]?.username,
        productID: id,
      };

      productToCart(payload)
        .then((data) => {
          dispatch(setUser(data.user));
        })
        .catch((err) => console.log("product error =>", err.message));
    } else {
      alert("Please login first...");
    }
  };

  const downloadObject = () => {
    // const get3DImage = product?.meta_data?.find((ele) => ele?.key === "glb");

    // window.open(
    //   `https://cdn.bimroom.com/glb/${get3DImage?.value}`
    // );
  };

  const insertObject = (product) => {
    if (product?.rfa?.length > 0) {
      const productName = product?.rfa[0]?.insertName;
      if (productName) {
        navigate("/download-start/?var=" + productName);
      }
    }
  };

  
  const handleModalClose = () => setShowModal(false);

  const handleModalShow = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const plugin = JSON.parse(localStorage.getItem("plugin"));
    if (plugin === "true") {
      setPluginMode(true);
    }
  }, [])

  return (
    <>
      <div className="action-buttons">
        <div className="favorite-project-wrapper ">
          {/* {user?.cart?.includes(product?.id) && (
            <div className="btn" onClick={() => handleCart(product?.id)}>
              Add to favorites
            </div>
          )} */}
          <div className="btn outlined" onClick={() => handleCart(product?.id)}>
            <img src="/icons/favorite.png" alt="" />
            <span>Add to favorites</span>
          </div>
          <div className="btn outlined">
            <img src="/icons/project.png" alt="" />
            <span>Add to Projects</span>
          </div>
        </div>

        {pluginMode ? <div className="btn contained" onClick={() => insertObject(product)}>
                        <img src="/icons/download.png" alt="" />
                        <span>Insert Object</span>
                      </div> :
                      <div className="btn contained" onClick={handleModalShow}>
                        <img src="/icons/download.png" alt="" />
                        <span>Download Object</span>
                      </div>
        }
      </div>

      <DownloadModal
        name={product?.name}
        data={product}
        brand={{
          brandLogo: product?.brandLogo,
          brandSlug: product?.brandSlug
        }}
        showModal={showModal}
       // downloadObject={get3DImage?.value}
        modalClose={handleModalClose}
      />
    </>
  );
}
