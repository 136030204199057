import React, { useState, useRef, useEffect, Suspense } from "react";

// packages
import Slider from "react-slick";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage, Center, Environment } from "@react-three/drei";
import Image3D from "./Image3D";

// images & icons
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import loaderImg from "../../../assets/loader/bimroom-loader.svg";
import emptyImage from "../../../assets/empty-image.png";
import ActionButtons from "./ActionButtons";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <p>Something went wrong.</p>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}

const tabArray = [
  { id: 0, name: "Render" },
  { id: 1, name: "3D Viewer" },
  { id: 2, name: "Product Images" }
];

export default function ProductView({ product }) {
  const sliderRef = useRef(null);

  // console.log(product);

  const [currentTab, setCurrentTab] = useState(tabArray[0].id);
  const [active, setActive] = useState(tabArray[0].name);
  const [show3DImage, setShow3DImage] = useState(false);

  const handleActive = (string) => {
    setActive(string);
  };

  const getImageFromAzureStorage = (image) => {
    return `https://cdn.bimroom.com/images/${image}`;
  };

  // const getRImage = product?.meta_data?.find((ele) => ele?.key === "r_image");
  // const get3DImage = product?.meta_data?.find((ele) => ele?.key === "glb");
  // const getPImage = product?.meta_data?.find((ele) => ele?.key === "p_image");

  const getRImage = product?.rImage;
  const get3DImage = product?.glb;
  const getPImage = product?.pImage;

  useEffect(() => {
    let isCancelled = false;
    setTimeout(() => {
      if (!isCancelled) setShow3DImage(true);
    }, 5000);

    return () => {
      isCancelled = true;
    };
  }, []);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="right-control" onClick={onClick}>
        <FaChevronRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="left-control" onClick={onClick}>
        <FaChevronLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => setCurrentTab(next),
  };

  return (
    <div className="product-view">
      <div className="product-view-sticky">
        <div className="tab-wrapper">
          {tabArray.map((data) => (
            <div
              className={data.id === currentTab ? "tab active" : "tab"}
              key={data.name}
              onClick={() => {
                setCurrentTab(data.id);
                sliderRef?.current?.slickGoTo(data.id);
              }}
            >
              {data.name}
            </div>
          ))}
        </div>

        <div className="slider">
          <Slider {...settings} ref={sliderRef}>
            <div className="slider-image">
              <img
                src={
                  getRImage !== undefined && getRImage !== ""
                    ? getImageFromAzureStorage(getRImage)
                    : getPImage !== undefined && getPImage !== ""
                    ? getImageFromAzureStorage(getPImage)
                    : emptyImage
                }
                alt=""
              />
            </div>

            <div className="slider-image">
              {get3DImage !== undefined && get3DImage !== "" && get3DImage.length > 0 ? (
                // <div
                //   className="image-view"
                //   style={{ alignItems: "flex-start" }}
                // >
                //   <div
                //     style={{
                //       position: "relative",
                //       width: 500,
                //       height: 500,
                //       marginTop: "50px",
                //     }}
                //   >
                //     <Canvas>
                //       <Suspense fallback={null}>
                //         <Stage
                //           environment={"warehouse"}
                //           intensity={1}
                //           shadows={false}
                //         >
                //           <Image3D image={get3DImage?.value} />
                //           <OrbitControls />
                //         </Stage>
                //       </Suspense>
                //     </Canvas>
                //   </div>
                // </div>
                <div className="image-view" style={{ alignItems: "flex-start" }}>
                <ErrorBoundary>
                  <div
                    style={{
                      position: "relative",
                      width: 500,
                      height: "100%",
                      //marginTop: "50px",
                    }}
                  >
                    {/* <Canvas>
                      <Suspense fallback={null}>
                        <Center alignTop={false}>
                          <Stage
                            //environment={null}
                            environment={null}
                            intensity={0.2}
                            // preset="rembrandt"
                            //shadows={false}
                            contactShadow={false}
                            adjustCamera={true}
                          >
                            <Environment path={"/"} files={"industrial_pipe_and_valve_02_2k.hdr"} background blur={0.5} />
                            <Image3D image={get3DImage} />
                            <OrbitControls rotation={true}/>
                          </Stage>
                        </Center>
                      </Suspense>
                    </Canvas> */}
                    <Canvas camera={{ fov: 50 }}>
                      <Suspense fallback={null}>
                        <Center alignTop={false}>
                          <Stage 
                            environment={null} 
                            intensity={0.4} 
                            //contactShadowOpacity={0.1} 
                            contactShadow={false}
                            shadowBias={-0.0015}
                            //adjustCamera={true}
                          >
                            <Environment
                              background={false} // Whether to affect scene.background
                              files={'industrial_pipe_and_valve_02_2k.hdr'}
                              path={'/assets/'}
                            />
                            <Image3D image={get3DImage} />
                          </Stage>
                        </Center>
                      </Suspense>
                      <OrbitControls autoRotate enableZoom={true} enablePan={true} />
                    </Canvas>
                  </div>
                </ErrorBoundary>
              </div>
              ) : (
              <div className="image-view">
                <img src={loaderImg} alt="" width={50} height={50} />
              </div>
              )}
            </div>

            <div className="slider-image">
              <img
                src={
                  getPImage !== undefined && getPImage[0] !== ""
                    ? getImageFromAzureStorage(getPImage[0])
                    : getRImage !== undefined && getRImage !== ""
                    ? getImageFromAzureStorage(getRImage)
                    : emptyImage
                }
                alt=""
              />
            </div>
          </Slider>
        </div>
        <ActionButtons product={product} />
      </div>
    </div>

    // <div className="product-view">
    //   <div className="product-view-sticky">
    //     <div className="tab-wrapper">
    //       {tabArray.map((data, index) => (
    //         <div
    //           className={data.name === active ? "tab active" : "tab"}
    //           onClick={() => {
    //             handleActive(data.name);
    //           }}
    //           key={index}
    //         >
    //           {data.name}
    //         </div>
    //       ))}
    //     </div>

    //     {tabArray[0].name === active && (
    //       <div className="image-view">
    //         <img
    //           src={
    //             getRImage !== undefined && getRImage?.value !== ""
    //               ? getImageFromAzureStorage(getRImage?.value)
    //               : getPImage !== undefined && getPImage?.value !== ""
    //               ? getImageFromAzureStorage(getPImage?.value)
    //               : emptyImage
    //           }
    //           alt=""
    //         />
    //       </div>
    //     )}

    //     {tabArray[1].name === active && (
    //       <>
    //         {show3DImage && get3DImage !== undefined ? (
    //           <div className="image-view" style={{ alignItems: "flex-start" }}>
    //             <div
    //               style={{
    //                 position: "relative",
    //                 width: 500,
    //                 height: 500,
    //                 marginTop: "50px",
    //               }}
    //             >
    //               <Canvas>
    //                 <Suspense fallback={null}>
    //                   <Stage
    //                     environment={"warehouse"}
    //                     intensity={1}
    //                     shadows={false}
    //                   >
    //                     <Image3D image={get3DImage?.value} />
    //                     <OrbitControls />
    //                   </Stage>
    //                 </Suspense>
    //               </Canvas>
    //             </div>
    //           </div>
    //         ) : (
    //           <div className="image-view">
    //             <img src={loaderImg} alt="" width={50} height={50} />
    //           </div>
    //         )}
    //       </>
    //     )}

    //     {tabArray[2].name === active && (
    //       <div className="slider">
    //         <Slider {...settings} ref={sliderRef}>
    //           <div className="slider-image">
    //             <img
    //               src={
    //                 getPImage !== undefined && getPImage?.value !== ""
    //                   ? getImageFromAzureStorage(getPImage?.value)
    //                   : getRImage !== undefined && getRImage?.value !== ""
    //                   ? getImageFromAzureStorage(getRImage?.value)
    //                   : emptyImage
    //               }
    //               alt=""
    //             />
    //           </div>

    //           <div className="slider-image">
    //             <img
    //               src={
    //                 getPImage !== undefined && getPImage?.value !== ""
    //                   ? getImageFromAzureStorage(getPImage?.value)
    //                   : getRImage !== undefined && getRImage?.value !== ""
    //                   ? getImageFromAzureStorage(getRImage?.value)
    //                   : emptyImage
    //               }
    //               alt=""
    //             />
    //           </div>
    //         </Slider>
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
}
