import React from "react";
import "./style.scss";

// packages
import { Link } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";

// components
import CategoryDropDown from "./CategoryDropDown";
import ProjectDropDown from "./ProjectDropDown";
import ProfileDropDown from "./ProfileDropDown";

// images
import logoDark from "../../../assets/navbar/bimroom-dark.svg";
import logoWhite from "../../../assets/navbar/bimroom-white.svg";

function handleLogin(instance) {
  instance.loginPopup(loginRequest).catch((e) => {
    console.error(e);
  });
}

function handleLogout(instance) {
  instance.logoutPopup().catch((e) => {
    console.error(e);
  });
}

export default function WebNavbar({ isHomeNavbar, handleAccountModal }) {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className={isHomeNavbar ? "web-navbar home-navbar" : "web-navbar"}>
      <Link to="/" className="logo">
        <img src={isHomeNavbar ? logoWhite : logoDark} alt="" />
      </Link>

      <div className="center">
        <CategoryDropDown />
      </div>

      <div className="right">
        <ProjectDropDown />

        {isAuthenticated ? (
          <ProfileDropDown
            logout={() => handleLogout(instance)}
            openAccountModal={handleAccountModal}
            account={accounts[0]}
          />
        ) : (
          <div className="login" onClick={() => handleLogin(instance)}>
            Login
          </div>
        )}
      </div>
    </div>
  );
}
