import React, { useEffect, useState } from "react";

// image's & icon's
import { FaCloudDownloadAlt } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import Brand from "../shared/Brand";

const ProjectList = ({ cart, handleCart, handleModalShow, list }) => {
  const navigate = useNavigate();
  const [pluginMode, setPluginMode] = useState(false);

  const goToProduct = (slug) => {
    navigate("/product/" + slug);
  }

  const getImage = (image) => {
    // console.log(image);
    if (image) {
      return `https://cdn.bimroom.com/images/${image}`;
    } else {
      console.log("no image found");
    }
  }

  const insertObject = (product) => {
    if (product?.rfa?.length > 0) {
      const productName = product?.rfa[0]?.insertName;
      if (productName) {
        navigate("/download-start/?var=" + productName);
      }
    }
  }
  
  useEffect(() => {
    const plugin = JSON.parse(localStorage.getItem("plugin"));
    if (plugin === "true") {
      setPluginMode(true);
    }
  }, [])

  //console.log(cart);
  return (
    <div className="favorite-list">
      {cart.length > 0 &&
        cart.map((data, index) => (
          <div className="single-item" key={index}>
            <div className="cross">
              <MdOutlineClose
                onClick={() => {
                  handleCart(data, list);
                }}
                className="icon"
              />
            </div>

            <div className="p-image">
              <img src={getImage(data?.image)} alt="" />
            </div>

            <div className="brand-image">
              {/* <img src={data?.brand} alt="" /> */}
              <Brand product={data} />
            </div>

            <div className="p-name">
              <span onClick={() => goToProduct(data?.slug)}>{data.name}</span>
            </div>

            <div className="download">
              <div className="d-btn" onClick={() => pluginMode ? insertObject(data) : handleModalShow(data) }>
                <HiDownload className="icon" />
              </div> 
            </div>
          </div>
        ))}

      {cart.length === 0 && (
        <div className="no-result">No products added to the project</div>
      )}
    </div>
  );
};

export default ProjectList;
