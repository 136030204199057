// import bimroom from "../assets/brand-images/b-collection-2x1.png";
import pic1 from "../assets/brand-images/b-collection-2x1.png";



export const brandLogos = [
  {
    icon: pic1,
    label: "Bimroom collection"
  }

];
