import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

// components
import HeroSection from "../components/Home/HeroSection";
import CategorySection from "../components/Home/CategorySection/CategorySection";
import FeaturedObjects from "../components/Home/FeaturedObjects/FeaturedObjects";
import ManufactureSection from "../components/Home/ManufactureSection/ManufactureSection";
import Footer from "../components/Footer";
import UnauthenticatedAccess from "../components/Home/UnauthenticatedAccess";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated
} from "@azure/msal-react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/home",
      },
    });
  }, []);
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    localStorage.setItem('downloading', JSON.stringify('false'));
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const brand = localStorage.getItem("brand");
      const value = searchParams.get("brand");
      if (brand) {
        navigate("/product/search/?brands=" + brand)
      } else if (value) {
        navigate("/product/search/?brands=" + value)
      }
    }
  }, [isAuthenticated])

  return (
    <>
      <AuthenticatedTemplate>
        <HeroSection />
        <CategorySection />
        <FeaturedObjects />
        <ManufactureSection />
        <Footer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Home;
