import React from "react";

import "./unauthenticatedAccess.scss";
import background from "../../../assets/images/home/login-theme.png";
import bimroomlogoorange from '../../../assets/images/home/bimroomlogoorange.svg'

import { loginRequest } from "../../../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

const UnauthenticatedAccess = () => {
  const { instance, accounts } = useMsal();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
    // <div style={{width: '100vw', height: '100vh', backgroundSize: 'cover', backgroundImage: `url(${background})`}}>
    <div style={{width: '100vw', height: '100vh', backgroundSize: 'cover', background: 'rgb(23,92,131)', background: 'linear-gradient(137deg, rgba(23,92,131,1) 0%, rgba(23,92,131,0.9517857313823967) 79%)'}}>    
      <div className="unauthenticated-section">
        <div className="unauthenticated-container">
          <div className="broom-logo">
            <img src={bimroomlogoorange} alt="" />
          </div>
          <div className="welcome-text">
            <br />
            {/* <p>Sign in to access Bimroom demo</p>
            <br /> */}
          </div>
          <div className="signin-btn" onClick={() => handleLogin(instance)}>
            Sign in
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthenticatedAccess;
