import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// packages
import TagManager from "react-gtm-module";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAllFilters } from "../redux/reducers/product-reducer";

// components
import Navbar from "../components/Navbar";
import ProductCatalog from "../components/ProductCatalog";
import Footer from "../components/Footer";
import withURLSync from "../components/ProductCatalog/URLSync";

//
import { CLIENT_URL } from "../config";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import UnauthenticatedAccess from "../components/Home/UnauthenticatedAccess";
import Loader from "../components/Loader";

// const searchClient = algoliasearch(
//   "MLN6C7QSR3",
//   "d5d83c9e0103cbfeef63fcd712daa7e3"
// );

const Catalog = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const dispatch = useDispatch();
  const key = useSelector((store) => store.user.user.searchKey);
  const isLoading = useSelector((store) => store.user.userState);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/product",
      },
    });
  }, []);

  useEffect(() => {
    document.title = "Catalog | bimroom"
  },[])

  useEffect(() => {
    if (key) {
      const searchClient = algoliasearch(
        "MLN6C7QSR3",
        key
      );
      setSearchKey(searchClient);
    }
  }, [key]);

  useEffect(() => {
    localStorage.removeItem("brand")
  }, [])


  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? (
            // <Loader text={"Generating manufacturer page"}/>
            <Loader text={"Loading catalog"}/>
          ) : (
            <>
              <Navbar isCatalogPage={true}/>
                <InstantSearch
                  indexName={"bimroom_demo_test"}
                  searchClient={searchKey}
                  searchState={props.searchState}
                  createURL={props.createURL}
                  onSearchStateChange={props.onSearchStateChange}
                >
                  <ProductCatalog 
                    searchState={props.searchState} 
                  />
                </InstantSearch>
              <Footer />
            </>
          )}
        {/*<Navbar />
         <InstantSearch
          indexName={"bimroom_demo_test"}
          searchClient={searchKey}
          searchState={props.searchState}
          createURL={props.createURL}
          onSearchStateChange={props.onSearchStateChange}
        >
          <ProductCatalog 
            searchState={props.searchState} 
          />
        </InstantSearch>
        <Footer /> */}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

export default withURLSync(Catalog);
